import React, {useEffect, useRef, useState} from "react";
import {Link} from "react-router-dom";

import {useDispatch, useSelector} from "react-redux";
import {setDealCreateData, setShowModal} from "../../../services/redux/actions/app-actions";
import {fetchAllDeals, fetchResidents, getHuntersList, getPresetsList} from "./api";

import {makeStyles} from "@material-ui/core/styles";
import DealsCol from "../view/DealsCol";
import CustomButton from "../../../components/UI/Button";
import styleConstants from "../../../constants/styleConstants";
import Search from "../../../components/UI/Search";
import CustomSelect from "../../../components/UI/Select";
import CustomDatePicker from "../../../components/UI/DatePicker";
import EmptyOrShow from "../../../components/EmptyOrShow";
import {AiFillPlusCircle} from "react-icons/ai";
import Layout from "../../../components/layout";
import {Box, Checkbox, InputLabel} from "@material-ui/core";
import AutoSuggest from "../../../components/UI/AutoSuggest";
import {getOrigins, getSaleDepart} from "../view/api";
import {LOCALSTORAGEFIELDS, MODALS} from "../../../constants";
import DownloadExcel from "../../../components/DownloadExcel";
import {getCallCenterManagers} from "../../reports/api";
import cookiesService from "../../../services/cookiesService";

const initialState = {
  resident_id: null,
  date_begin: null,
  date_end: null,
  hunter_ids: null,
  origin_ids: null,
  is_auditor_checked: null,
  is_contract_signed: null,
  is_requisites_checked: null,
  sale_department_id: null,
  is_archive: false,
};

const cookiesServiceInstance = cookiesService.getService();

export const Deals = () => {
  const boardRef = useRef();
  const dispatch = useDispatch();
  const classes = useStyles();

  const [loading, setLoading] = useState(true);
  const [activeHunters, setActiveHunters] = useState([]);
  const [origins, setOrigins] = useState([]);
  const [, setPresets] = useState([]);
  const [saleDepart, setSaleDepart] = useState([]);
  const [managers, setManagers] = useState([]);
  const [value, setValue] = useState(initialState);
  const [search, setSearch] = useState("");
  const [data, setData] = useState([]);
  const [residents, setResidents] = useState([]);

  const {position_code} = cookiesServiceInstance.getUserData();
  const {cityId} = useSelector(state => state.appState);
  const city_id = localStorage.getItem(LOCALSTORAGEFIELDS.city);

  useEffect(() => {
    Promise.all([
      getOrigins(),
      getPresetsList(),
      getSaleDepart(),
      getCallCenterManagers()
    ])
      .then(res => {
        const [
          originList,
          presetsList,
          saleDepartList,
          callCenterManagers
        ] = res;
        setOrigins(originList);
        setPresets(presetsList);
        setSaleDepart(saleDepartList);
        setManagers(callCenterManagers);
      })
      .catch(() => {
      });
  }, []);

  const allDeals = param => {
    setLoading(true);
    fetchAllDeals(param)
      .then(res => {
        setData(res);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchDeals = () => {
    cityId !== "" && allDeals({...value, city_id: cityId});
  };

  useEffect(() => {
    fetchResidents({city_id: city_id === "null" ? null : city_id}).then(res => setResidents(res));
    setValue(initialState);
  }, [city_id]);

  const handleChange = (e, v, name) => {
    if (typeof v === "object" && v) {
      setValue(prev=> ({...prev, [name]: v[name]}));
    } else {
      setValue(prev=> ({...prev, [name]: v}));
    }
  };

  const handleSearch = e => {
    setSearch(e.target.value);
  };

  const handleClearDate = name => {
    setValue(prev => ({...prev, [name]: null}));
  };

  useEffect(() => {
    cityId !== "" && allDeals({
      ...value,
      city_id: cityId === "null" || cityId === "" ? null : cityId
    });
  }, [value, cityId]);

  const handleHunterChange = e => {
    setValue(prev => ({
      ...prev,
      [e.target.name]: e.target.value?.length ? e.target.value : null
    }));
  };

  const handleSingleChange = (e, isCheckbox = false) => {
    if(isCheckbox === true)
      return setValue(prev => ({...prev, [e.target.name]: e.target.checked}))
    setValue(prev => ({...prev, [e.target.name]: e.target.value}))
  };

  useEffect(() => {
    getHuntersList().then(res => {
      setActiveHunters(res);
    });
  }, [cityId]);

  return (
    <Layout title="Список сделок">
      <Box sx={{paddingBottom: 20}}>
        <Box className={classes.main_block}>
          <Box className={classes.control_block}>
            <Box className={classes.btn_add}>
              <Link to={"/deal"} className={classes.link}>
                <CustomButton
                  btnText={"Добавить"}
                  startIcon={
                    <AiFillPlusCircle style={{width: 34, height: 32}}/>
                  }
                  handleClick={() => dispatch(setDealCreateData(null))}
                />
              </Link>
            </Box>
            <Box width={200}>
              <CustomButton
                btnText={"Проверка по ИИН"}
                handleClick={() =>
                  dispatch(
                    setShowModal(true, MODALS.checkIin, {
                      title: "Провека по ИИН"
                    })
                  )
                }
              />
            </Box>
            <Box className={classes.search}>
              <Search
                placeholder={"Поиск"}
                handleChange={handleSearch}
                value={search}
              />
            </Box>
            <Box className={classes.select_block}>
              <CustomSelect
                label={"Жилой комплекс"}
                placeholder="Все ЖК"
                options={residents}
                optionLabel={"resident_name"}
                handleChange={handleChange}
                name="resident_id"
                selected={value["resident_id"]}
                valueKey="resident_id"
              />
            </Box>
            <Box className={classes.select_date}>
              <CustomDatePicker
                label={"Дата с"}
                placeholder="Введите дату"
                handleChange={handleChange}
                name="date_begin"
                value={value["date_begin"]}
                handleClearDate={handleClearDate}
              />
            </Box>
            <Box className={classes.select_date}>
              <CustomDatePicker
                label={"Дата по"}
                placeholder="Введите дату"
                handleChange={handleChange}
                name="date_end"
                value={value["date_end"]}
                handleClearDate={handleClearDate}
              />
            </Box>
            {position_code !== "SELLER" && position_code !== "SALE_HEAD" && <Box>
              <DownloadExcel
                url="crm/crm_deals/xlsx/"
                params={{
                  ...value,
                  city_id: cityId === "null" || cityId === "" ? null : cityId
                }}
                title="Сделки"
              />
            </Box>}
          </Box>

          <Box className={classes.control_block}>
            {position_code === "SELLER" ? null : (
              <Box className={classes.multiple}>
                <AutoSuggest
                  isMultiple={true}
                  label={"Участвующие хантеры"}
                  placeholder="Выберите хантера"
                  name="hunter_ids"
                  selected={value["hunter_ids"]}
                  list={activeHunters}
                  valueKey="employee_id"
                  showKey="fio"
                  onChange={handleHunterChange}
                />
              </Box>
            )}

            <Box width={100}>
              <AutoSuggest
                label={"Проверено аудитором"}
                placeholder="Выберите"
                name="is_auditor_checked"
                selected={value["is_auditor_checked "]}
                list={booleanList}
                valueKey="value"
                showKey="show"
                onChange={handleSingleChange}
              />
            </Box>
            <Box width={100}>
              <AutoSuggest
                label={"Договор подписан"}
                placeholder="Выберите"
                name="is_contract_signed"
                selected={value["is_contract_signed "]}
                list={booleanList}
                valueKey="value"
                showKey="show"
                onChange={handleSingleChange}
              />
            </Box>
            <Box width={100}>
              <AutoSuggest
                label={"Заполнены реквизиты"}
                placeholder="Выберите"
                name="is_requisites_checked"
                selected={value["is_requisites_checked "]}
                list={booleanList}
                valueKey="value"
                showKey="show"
                onChange={handleSingleChange}
              />
            </Box>
            <Box className={classes.multiple}>
              <AutoSuggest
                isMultiple={true}
                label={"Источник лида"}
                placeholder="Выберите"
                name="origin_ids"
                selected={value["origin_ids"]}
                list={origins}
                valueKey="origin_id"
                showKey="origin_name"
                onChange={handleHunterChange}
              />
            </Box>
            {position_code === "SALE_HEAD_HEAD" ||
            position_code === "CALL_CENTER" ||
            position_code === "SALE_AUDITOR" ||
            position_code === "CALL_CENTER_HEAD" ? (
              <Box className={classes.select_block}>
                <AutoSuggest
                  label="Отдел продаж"
                  placeholder="Выберите"
                  name={"sale_department_id"}
                  selected={value["sale_department_id "]}
                  list={saleDepart}
                  valueKey="sale_department_id"
                  showKey="sale_department_name"
                  onChange={handleSingleChange}
                />
              </Box>
            ) : null}
            {position_code === "SELLER" ||
            position_code === "CALL_CENTER" ? null : (
              <Box className={classes.select_block}>
                <AutoSuggest
                  label="Менеджер КЦ"
                  placeholder="Выберите"
                  name={"manager_cc_id"}
                  selected={value["manager_cc_id"]}
                  list={managers}
                  valueKey="employee_id"
                  showKey="fio"
                  onChange={handleSingleChange}
                />
              </Box>
            )}
            <Box className={classes.archive_block}>
              <Checkbox
                name="is_archive"
                label="Архив" value={value.is_archive} checked={value.is_archive}
                onChange={e => {
                  handleSingleChange({target: e.currentTarget}, true)
                }}
                color="primary"
              />
              <InputLabel className={classes.label}>Архив</InputLabel>
            </Box>
          </Box>
        </Box>

        <EmptyOrShow loading={loading}>
          <Box className={classes.board} ref={boardRef}>
            {data?.map(el => (
              <DealsCol
                key={el.crm_status_id}
                {...el}
                el={el}
                search={search}
                fetchDeals={fetchDeals}
              />
            ))}
          </Box>
        </EmptyOrShow>
      </Box>
    </Layout>
  );
};

export default Deals;

const booleanList = [
  {
    value: true,
    show: "Да"
  },
  {
    value: false,
    show: "Нет"
  }
];

const useStyles = makeStyles({
  link: {
    textDecoration: "none"
  },
  main_block: {
    padding: "45px 20px 0 20px"
  },
  control_block: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-end",
    gap: 20,
    margin: "20px 0"
  },
  btn_add: {
    width: 150
  },
  search: {
    width: 400
  },
  archive_block: {
    width: 80,
    display: "flex",
    alignItems: 'center',
  },
  select_block: {
    width: 200
  },
  select_date: {
    width: 200,

    "& button": {
      padding: 0
    }
  },
  board: {
    display: "grid",
    gridTemplateColumns: "repeat(8, 1fr)",
    margin: "0 20px",
    paddingBottom: "20px",
    overflowX: "auto",
    overflowY: "hidden",
    height: "calc(100vh - 70px)",
    scrollbarColor: "#6191EF #fff",
    scrollbarWidth: "thin",

    "&::-webkit-scrollbar": {
      height: 7
    },

    "&::-webkit-scrollbar-thumb": {
      backgroundColor: styleConstants.primaryColor,
      borderRadius: styleConstants.borderRadius
    }
  },
  multiple: {
    flex: 1
  },
  wrapper_filter: {
    margin: "20px 0",
    gap: 20
  },
  label: {
    fontSize: 12,
    fontWeight: 600,
    color: styleConstants.darkGray,
    marginBottom: 3
  },
});
