import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
  setBtnStatuses,
  setDealCreateData,
  setShowModal,
  setStatusInfo
} from "../../../services/redux/actions/app-actions";

import {makeStyles} from "@material-ui/core/styles";
import {Box} from "@material-ui/core";
import {Formik} from "formik";
import {getDealFields, getDealStatuses, updateDealFields} from "pages/deals/view/api";
import InputText from "components/Form/InputText";
import ProgressR from "components/Form/ProgressR";
import AutoSuggest from "components/UI/AutoSuggest";
import CustomButton from "components/UI/Button";
import {parseDate, toShowDate} from "../../helpers";
import {useCustomSnackbar} from "../../../utils/hooks/useCustomSnackbar";

const useStyles = makeStyles({
  form: {
    width: 580,
    maxWidth: "80vw"
  }
})

export default function FormBuilderModal({btnStatuses}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {addDefaultSnack} = useCustomSnackbar();

  const [loading, setLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [initValues, setInitValues] = useState({});
  const {formBuilderFields, crm_deal_id, client_type_id} = useSelector(
    state => state.appState.dealCreateData
  );

  useEffect(() => {
    setIsFetching(true);
    getDealFields(crm_deal_id, client_type_id).then(res => {
      let inV = {};
      if (res.fields)
        res.fields.forEach(el => {
          inV[el.field_id] = el.field_value;
        });
      dispatch(
        setDealCreateData({
          formBuilderFields: res.fields
        })
      );
      setInitValues(inV);
      setIsFetching(false);
    }).catch(() => setIsFetching(false));
  }, [dispatch, crm_deal_id, client_type_id]);

  const postData = values => {
    let params = formBuilderFields.map(i => ({
      ...i,
      field_value: values[i.field_id]
    }));
    setLoading(true)
    updateDealFields(crm_deal_id, params, client_type_id)
      .then(res => {
        setLoading(false)
        addDefaultSnack("Дополнительные поля успешно записаны!");
        dispatch(setShowModal(false));
        dispatch(setBtnStatuses({...btnStatuses, extra_btn: res.extra_btn}));

        getDealStatuses(crm_deal_id)
          .then(res => {
            dispatch(setStatusInfo(res));
          })
          .catch(() => {
          });
      })
      .catch(() => {
        setLoading(false)
      });
  };

  return (
    <ProgressR width="580px" loading={isFetching}>
      <Formik
        enableReinitialize
        initialValues={initValues || {}}
        validateOnChange={false}
        onSubmit={(values, {resetForm, setSubmitting}) => {
          postData(values, resetForm, setSubmitting);
        }}
      >
        {props => {
          let {
            values,
            handleChange,
            handleSubmit,
          } = props;
          return (
            <form className={classes.form}>
              <ProgressR
                minHeight="50px"
                loading={loading}
              />
              {formBuilderFields?.map(i => (
                <FormItem
                  key={i.field_id}
                  value={values[i.field_id]}
                  {...i}
                  handleChange={handleChange}
                />
              ))}

              <Box display="flex" justifyContent="flex-end">
                <CustomButton btnText="Сохранить" handleClick={handleSubmit}/>
              </Box>
            </form>
          );
        }}
      </Formik>
    </ProgressR>
  );
}

const FormItem = ({
                    field_id,
                    field_type,
                    field_name,
                    value,
                    list,
                    handleChange
                  }) => {
  switch (field_type) {
    case "LIST":
      return (
        <AutoSuggest
          label={field_name}
          name={field_id + ""}
          selected={parseInt(value)}
          list={list}
          valueKey="id"
          showKey="name"
          onChange={handleChange}
        />
      );
    case "INTEGER":
      return (
        <InputText
          name={field_id + ""}
          label={field_name}
          numberText
          value={value}
          onChange={handleChange}
        />
      );
    case "DATE":
      return (
        <InputText
          name={field_id + ""}
          label={field_name}
          type="date"
          value={parseDate(value, "yyyy-MM-dd")}
          onChange={e => {
            handleChange({
              target: {name: field_id, value: toShowDate(e.target.value)}
            })
          }
          }
        />
      );
    case "VARCHAR":
      return (
        <InputText
          name={field_id + ""}
          label={field_name}
          value={value}
          onChange={handleChange}
        />
      );
    default:
      return <></>;
  }
};
