import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {setDealCreateData, setDealDocs, setShowModal, setTasks} from "../../../services/redux/actions/app-actions";

import {makeStyles} from "@material-ui/core/styles";
import globalUseStyles from "../../../components/globalStyles";
import {Box, Button} from "@material-ui/core";
import {MdKeyboardArrowRight} from "react-icons/all";

import {getDealAllSales, getDealTasks, getFormInfo, getMainDocs, removeSale, resign} from "./api";
import SaleItem from "./SaleItem";
import clsx from "clsx";
import styleConstants from "../../../constants/styleConstants";
import {MAIN_URL, MODALS} from "../../../constants";
import {PlusIcon} from "../../../components/UI/Icons";
import IndicatorIcon from "../../../components/UI/IndicatorIcon";
import kitchen from "../../../assets/images/kitchen.svg";
import tv from "../../../assets/images/tv.svg";
import cert from "../../../assets/images/cert.svg";

import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import cookiesService from "../../../services/cookiesService";
import {useCustomSnackbar} from "../../../utils/hooks/useCustomSnackbar";
import {recountClientChoice} from "../services";
import {formatSum} from "../../../utils";

const useStyles = makeStyles({
  client_choice: {
    backgroundColor: "#fff",
    border: "1px solid #e2e3ed",
    borderRadius: 4,
    padding: 20,

    "& h4": {
      fontSize: 14,
      fontWeight: 600,
      lineHeight: "17px",
      color: "#494c62",
      margin: "0 0 15px 0"
    }
  },
  client_choice_inner: {
    display: "flex",
    flexWrap: "wrap",
    gap: 20
  },
  packet_choice_wrapper: {
    "& button": {
      height: 30
    }
  },
  packet_choice_btn: {
    color: "#fff",
    fontWeight: 600,
    backgroundColor: styleConstants.primaryColor,
    display: "flex",
    justifyContent: "space-between",
    width: 230,

    "&:hover": {
      backgroundColor: styleConstants.primaryColor
    }
  },
  preset_wrapper: {
    display: "flex",
    alignItems: "center",
    gap: 5,
    marginTop: 16
  },
  preset_link_wrapper: {
    backgroundColor: "#f5f5f9",
    borderRadius: 4,
    width: 270,
    padding: 12
  },
  preset_link: {
    fontWeight: 600,
    color: "#6191ef",
    textDecoration: "underline",
    marginBottom: 10
  },
  preset_item: {
    fontSize: 13,
    marginTop: 5
  },
  preset_btn: {
    fontSize: 12,
    fontWeight: 600,
    color: styleConstants.primaryColor,
    backgroundColor: "#fff",
    border: `1px solid ${styleConstants.primaryColor}`,
    borderRadius: 4,
    width: 40,
    height: 30,
    cursor: "pointer"
  },
  credit_btn: {
    color: styleConstants.primaryColor,
    fontSize: 14,
    fontWeight: 600,
    textAlign: "left"
  },
  tech_block: {
    display: "flex",
    alignItems: "center",
    gap: 10,
    marginTop: 10
  },
  tech_feature_btn: {
    fontWeight: 600,
    color: "#fff",
    border: "none",
    borderRadius: styleConstants.borderRadius,
    padding: "8px 10px",
    cursor: "pointer"
  },
  cert_icon: {
    width: '20px',
    height: '20px',
    marginRight: '5px'
  },
  cert_block: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 13,
    marginTop: 5,
    paddingTop: '5px'
  },
  cert_sign: {
    padding: '10px',
    width: '248px'
  },
  cert_btn: {
    width: '100%',
    fontWeight: '600',
    fontSize: '13px'
  },
  resign_btn: {
    backgroundColor: '#6191EF',
    width: '100%',
    color: '#fff',
    fontWeight: '600',
    height: 'auto !important',
    lineHeight: 'initial',

    "&:hover": {
      backgroundColor: styleConstants.primaryColor
    }
  },
  deleted_signs: {
    fontSize: 13,
    marginTop: 5,
    color: 'red'
  }
});

const cookiesServiceInstance = cookiesService.getService();
const ClientsChoice = () => {
  const classes = useStyles();
  const globalClasses = globalUseStyles();
  const dispatch = useDispatch();
  const {addDefaultSnack} = useCustomSnackbar();

  const [dealSaleInfo, setDealSaleInfo] = useState({});
  const {dealCreateData} = useSelector(state => state.appState);
  const {btnStatuses} = useSelector(state => state.appState);

  const {position_code, employee_id} = cookiesServiceInstance.getUserData();
  const {
    preset,
    crm_deal_id,
    client_request_main_id,
    total_price,
    deleted_signs
  } = dealCreateData

  const clientRequestMainId = preset?.client_request_main_id

  const {kitchen_btn, technique_btn, tech_feature_btn} = btnStatuses;

  const handleCopyClick = () => {
    navigator.clipboard.writeText(preset?.client_sign_url).then(function () {
      addDefaultSnack("Скопировано в буфер обмена");
    });
  };

  useEffect(() => {
    if (crm_deal_id && clientRequestMainId) {
      getDealAllSales(crm_deal_id).then(res => {
        setDealSaleInfo(res);
      });
    }
  }, [crm_deal_id, clientRequestMainId]);

  const handleRemoveSale = params => {
    removeSale(crm_deal_id, params)
      .then(res => {
        setDealSaleInfo(res);
        addDefaultSnack();
        dispatch(setShowModal(false));
      })
      .catch(() => {
      });
  };

  const handleResign = params => {
    if (!window.confirm("Вы действительно хотите отозвать подпись клиента?")) {
      return false;
    }
    resign(crm_deal_id, params)
      .then(res => {
        if (res?.length) {
          getFormInfo(crm_deal_id, 0).then(res => dispatch(setDealCreateData(res)));
          getDealTasks(crm_deal_id).then(res => dispatch(setTasks(res)));
          getMainDocs(crm_deal_id).then(res => dispatch(setDealDocs(res)));
        }
        addDefaultSnack();
      })
      .catch(() => {
      });
  };

  const {discounts, sales, installment} = dealSaleInfo;
  const [anchorEl, setAnchorEl] = React.useState({});

  const handleClick = (id, event) => {
    setAnchorEl(prev => ({...prev, [id]: event.currentTarget}));
  };

  const onRecountClick = () => {
    dispatch(
      setShowModal(true, MODALS.confirmDelete, {
        title: "Подтвердите действие",
        submitText: "Подтвердить", deleteMode: false,
        handleDelete: handleRecount
      })
    );
  }
  const handleRecount = async () => {
    if(!crm_deal_id) return
    const res = await recountClientChoice(crm_deal_id)
    if(!res?.status) return
    dispatch(setShowModal(false))
    window.location.reload();
  };

  const handleClose = (id) => {
    setAnchorEl(prev => ({...prev, [id]: null}));
  };

  return (
    <Box
      className={clsx(classes.client_choice, {
        [globalClasses.disable_block]:
        !crm_deal_id || position_code === "SALE_ARCHIVE"
      })}
    >
      <h4>Выбор клиента</h4>
      <Box className={classes.client_choice_inner}>
        <Box className={classes.packet_choice_wrapper}>
          <Button
            disableElevation
            endIcon={<MdKeyboardArrowRight size={20} color="#fff"/>}
            className={classes.packet_choice_btn}
            onClick={() =>
              dispatch(
                setShowModal(true, MODALS.presetChoice, {
                  title: "Выбор пакета"
                })
              )
            }
          >
            Выбор пакета
          </Button>
          {preset?.client_request_main_id ? (
            <Box className={classes.preset_wrapper}>
              <Box className={classes.preset_link_wrapper}>
                <a
                  className={classes.preset_link}
                  target="_blank" rel="noopener noreferrer"
                  href={`${MAIN_URL}/request/constructor-pick/employee_id/${employee_id}/crm_deal_id/${crm_deal_id}/client_request_id/${preset?.client_request_main_id}/`}
                  title={preset?.preset_info}
                >
                  {preset?.preset_info}
                </a>
                <Box className={classes.preset_item}>
                  ID заявки: {client_request_main_id}
                </Box>
                <Box className={classes.preset_item}>
                  Общая сумма: {formatSum(total_price)} тг
                </Box>
                <Box className={classes.preset_item}>
                  Дата заявки: {preset?.request_date}
                </Box>
                <Box className={classes.preset_item}>
                  Грейд: {preset?.grade_name}
                </Box>
                {deleted_signs ? (
                  <Box className={classes.deleted_signs}>
                    Кол-во отозванных подписей: <b>{Object.keys(deleted_signs).length}</b>
                  </Box>
                ) : null}
                <Box className={classes.cert_block}>
                  <Button variant="contained" disabled={!!preset?.is_client_signed}
                          className={classes.cert_btn} onClick={onRecountClick}>
                    Пересчитать
                  </Button>
                </Box>
                {preset?.is_client_signed ? (
                  <Box className={classes.cert_block}>
                    <Button aria-describedby={!!anchorEl['client'] ? 'simple-popover' : undefined} variant="contained"
                            className={classes.cert_btn} onClick={(e) => handleClick('client', e)}>
                      <img src={cert} className={classes.cert_icon} title="Подпись" alt="cert_icon"/>
                      Подписан клиентом
                    </Button>
                    <Popover
                      id={!!anchorEl['client'] ? 'simple-popover' : undefined}
                      open={!!anchorEl['client']}
                      anchorEl={anchorEl.client}
                      onClose={(e) => handleClose('client', e)}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                    >
                      <Typography className={classes.cert_sign}>{preset?.client_dn_name}</Typography>
                    </Popover>
                  </Box>
                ) : null}
                {preset?.is_operator_signed ? (
                  <Box className={classes.cert_block}>
                    <Button aria-describedby={!!anchorEl['operator'] ? 'simple-popover' : undefined} variant="contained"
                            className={classes.cert_btn} onClick={(e) => handleClick('operator', e)}>
                      <img src={cert} className={classes.cert_icon} title="Подпись" alt="cert_icon"/>
                      Подписан оператором
                    </Button>
                    <Popover
                      id={!!anchorEl['operator'] ? 'simple-popover' : undefined}
                      open={!!anchorEl['operator']}
                      anchorEl={anchorEl.operator}
                      onClose={(e) => handleClose('operator', e)}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                    >
                      <Typography className={classes.cert_sign}>{preset?.operator_dn_name}</Typography>
                    </Popover>
                  </Box>
                ) : null}
                {preset?.is_show_resign ? (
                  <Box className={classes.cert_block}>
                    <Button variant="contained" className={classes.resign_btn}
                            onClick={() => handleResign(preset.client_sign_id)}>
                      Отозвать подпись клиента
                    </Button>
                  </Box>
                ) : null}
              </Box>
              <button
                className={classes.preset_btn}
                onClick={handleCopyClick}
                title="Скопировать ссылку на подпись ЭЦП"
              >
                ЭЦП
              </button>
            </Box>
          ) : null}
        </Box>
        <Box>
          <Button
            className={`${
              !crm_deal_id
                ? clsx(classes.credit_btn, globalClasses.btn_disable)
                : classes.credit_btn
            } `}
            endIcon={<PlusIcon/>}
            onClick={() =>
              dispatch(
                setShowModal(true, MODALS.sales, {
                  title: "Кредит/рассрочка/скидка/надбавка/акция",
                  setDealSaleInfo,
                  crmDealId: crm_deal_id
                })
              )
            }
          >
            Кредит/рассрочка/скидка/надбавка/акция
          </Button>
          {discounts?.map(el => {
            return (
              <SaleItem
                key={el.remont_discount_id}
                sale_name={`${el.discount_name} ${el.discount_amount}  `}
                sale_type_name={el.is_allowance ? `Надбавка ${el.rowversion}` : `Скидка ${el.rowversion}`}
                material_area_discount={el.material_area_discount}
                handleRemoveSale={() =>
                  dispatch(
                    setShowModal(true, MODALS.confirmDelete, {
                      title: "Удаление скидки",
                      handleDelete: () =>
                        handleRemoveSale({
                          sale_type_code: "DISCOUNT",
                          remont_discount_id: el.remont_discount_id
                        })
                    })
                  )
                }
              />
            );
          })}
          {sales?.map(el => {
            return (
              <SaleItem
                key={el.remont_sale_id}
                sale_name={el.sale_name}
                sale_type_name={"Акция"}
                handleRemoveSale={() =>
                  dispatch(
                    setShowModal(true, MODALS.confirmDelete, {
                      title: "Удаление акции",
                      handleDelete: () =>
                        handleRemoveSale({
                          sale_type_code: "STOCK",
                          remont_sale_id: el.remont_sale_id
                        })
                    })
                  )
                }
              />
            );
          })}
          {installment?.bank_name ? (
            <SaleItem
              key={installment.bank_id}
              sale_name={installment.bank_name}
              sale_type_name={installment?.sale_type_name}
              credit_sum={installment.credit_sum}
              detailView
              handleRemoveSale={() =>
                dispatch(
                  setShowModal(true, MODALS.confirmDelete, {
                    title: "Удаление рассрочки",
                    handleDelete: () =>
                      handleRemoveSale({
                        sale_type_code: "INSTALLMENT"
                      })
                  })
                )
              }
              onClickDetail={() =>
                dispatch(
                  setShowModal(true, MODALS.sales, {
                    title: "Рассрочка",
                    body: installment
                  })
                )
              }
            />
          ) : null}
          <Box className={classes.tech_block}>
            <IndicatorIcon
              bg={technique_btn ? "#77BF6C" : "#D2D3D9"}
              handleClick={() => {
                dispatch(
                  setShowModal(true, MODALS.technics, {
                    title: "Техника",
                    crm_deal_id
                  })
                );
              }}
            >
              <img src={tv} title="Техника" alt="technics_icon"/>
            </IndicatorIcon>
            <IndicatorIcon
              bg={kitchen_btn ? "#77BF6C" : "#D2D3D9"}
              handleClick={() => {
                dispatch(
                  setShowModal(true, MODALS.kitchen, {
                    title: "Кухня",
                    crm_deal_id
                  })
                );
              }}
            >
              <img src={kitchen} title="Кухня" alt="kitchen_icon"/>
            </IndicatorIcon>
            <button
              className={`${
                tech_feature_btn
                  ? clsx(classes.tech_feature_btn, globalClasses.btn_green)
                  : clsx(classes.tech_feature_btn, globalClasses.btn_grey)
              }`}
              onClick={() => {
                dispatch(
                  setShowModal(true, MODALS.techFeature, {
                    title: "Технические особенности",
                    crm_deal_id,
                    btnStatuses
                  })
                );
              }}
            >
              Технические особенности
            </button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ClientsChoice;
