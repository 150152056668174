import React, {useEffect, useState} from "react";

import {makeStyles} from "@material-ui/core/styles";
import {Box} from "@material-ui/core";

import CustomDatePicker from "../../../components/UI/DatePicker";
import {getCallCenterManagers, getTaskTypeCallCenter} from "../api";
import AutoSuggest from "../../../components/UI/AutoSuggest";

const useStyles = makeStyles({
  filters: {
    display: "flex",
    gap: 20,
  },
  date_picker: {
    maxWidth: 200,

    "& label": {
      fontWeight: 400,
    },

    "& button": {
      padding: 0,
    },
  },
});

const Filters = ({ state, setState }) => {
  const classes = useStyles();
  const [saleCall, setSaleCall] = useState([]);
  const [taskTypes, setTaskTypes] = useState([]);

  useEffect(() => {
    getCallCenterManagers().then((res) => {
      setSaleCall(res);
    });
  }, []);

  useEffect(() => {
    getTaskTypeCallCenter().then((res) => {
      setTaskTypes(res);
    });
  }, []);

  const handleChange = (e, v, name) => {
    if (v) {
      setState({ ...state, [name]: v });
    } else {
      setState({
        ...state,
        [e.target.name]: e.target.value ? e.target.value : null,
      });
    }
  };

  const handleChangeSelect = (e) => {
    setState(prev => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleClearDate = (name) => {
    setState({ ...state, [name]: null });
  };

  return (
    <Box className={classes.filters}>
      <Box minWidth={250}>
        <AutoSuggest
          label="Менеджер КЦ"
          placeholder="Выберите"
          name={"manager_id"}
          value={state?.manager_id || ''}
          list={saleCall}
          valueKey="employee_id"
          showKey="fio"
          onChange={handleChangeSelect}
        />
      </Box>

      <Box minWidth={250}>
        <AutoSuggest
          label="Тип задачи"
          placeholder="Выберите"
          name={"crm_task_type_id"}
          value={state?.crm_task_type_id || ''}
          list={taskTypes}
          valueKey="crm_task_type_id"
          showKey="crm_task_type_name"
          onChange={handleChangeSelect}
        />
      </Box>

      <Box className={classes.date_picker}>
        <CustomDatePicker
          name="date_begin"
          label="Дата с"
          placeholder="Введите дату"
          handleChange={handleChange}
          value={state.date_begin}
          handleClearDate={handleClearDate}
        />
      </Box>
      <Box className={classes.date_picker}>
        <CustomDatePicker
          name="date_end"
          label="Дата по"
          placeholder="Введите дату"
          handleChange={handleChange}
          value={state.date_end}
          handleClearDate={handleClearDate}
        />
      </Box>
    </Box>
  );
};

export default Filters;
