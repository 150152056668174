export const API_URL = process.env.REACT_APP_API_URL;
export const MAIN_URL = process.env.REACT_APP_MAIN_URL;
export const SYSTEM_COUNTRY_CODE = process.env.REACT_APP_SYSTEM;

export const COUNTRY_CODE = {
  UZB: "UZB",
  KZ: "KZ",
};

export const PAGES = {
  main: "/",
  login: "/login",
  profile: "/profile",
  deals: "/deals",
  dealView: "/deal",
  tasks: "/tasks",
  clients: "/clients",
  client: "/client",
  reports: "/reports",
  salesDeptPlan: "/sales_dept_plan",
  huntersPlan: "/hunters_plan",
  coefficient: "/coefficient",
  huntersKpi: "/hunters_kpi",
  hunter: "/hunter",
  contract: "/contract",
  discount: "/discount",
  resetPassword: "/reset_password",
  ccTask: "/cc-task",
  searchByPhone: "/search-by-phone",
};

export const MODALS = {
  bankData: "bankData",
  docData: "docData",
  contactData: "contactData",
  formBuilder: "formBuilder",
  addTask: "addTask",
  payment: "payment",
  keyTransfer: "keyTransfer",
  presetChoice: "presetChoice",
  additionalDocs: "additionalDocs",
  uploadDocs: "uploadDocs",
  technics: "technics",
  kitchen: "kitchen",
  confirmDelete: "confirmDelete",
  addClient: "addClient",
  endTask: "endTask",
  sales: "sales",
  techFeature: "techFeature",
  deniedContract: "deniedContract",
  huntersModal: "huntersModal",
  checkDocument: "checkDocument",
  residentsModal: "residentsModal",
  checkIin: "checkIin",
  aboutRepair: "aboutRepair",
  changeDataModal: "changeDataModal",
  addMonthModal: "addMonthModal",
  changeKpiModal: "changeKpiModal",
  changeSumModal: "changeSumModal",
  addHunterModal: "addHunterModal",
  penaltyListModal: "penaltyListModal",
  addPenaltyModal: "addPenaltyModal",
  closeMonthModal: "closeMonthModal",
  changeCoeffModal: "changeCoeffModal",
  addCoeffModal: "addCoeffModal",
  CallCenterCheck: "CallCenterCheck",
};

export const SUCCESS = "success";
export const ERROR = "error";

export const HEADER_FIELDS = {
  isTaskList: "isTaskList",
  isTaskFilter: "isTaskFilter",
};

export const LOCALSTORAGEFIELDS = {
  city: "city",
  month: "month",
};

export const COOKIES_FIELDS = {
  accessToken: "accessToken",
  refreshToken: "refreshToken",
  userData: "userData"
};
export const regexOfOnlyNumbers = /^\+[0-9]+$/;

export const clientTypes = {
  LEGAL_ENTITIES: "LEGAL_ENTITIES"
}
